import React from 'react';

export default function Container(props) {

    return (
        <div className="container px-4 md:px-0 max-w-6xl mx-auto -mt-16">
            <div className="mx-0 sm:mx-6">
                <div className="bg-gray-200 w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">
                    <div className="flex h-full bg-white rounded overflow-hidden shadow-lg mb-10">
                        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
                            <div className="px-6 py-4">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

