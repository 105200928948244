import CardTitle from "../Titles/card-title";
import Date from "../Text/date";
import Text from "../Text/text";
import CardLink from "../Link/card-link";
import BookButton from "../Button/book";
import React from "react";

export default function Card(props) {


    const openModal = function() {
        props.selectGift(props.giftId);
        props.showModal(true);
    };

    return (
        <div className={`w-full rounded border-gray-300 border shadow-md ${props.img !== "" ? "flex" : "px-4 py-2"}`}>
            {props.img !== "" ?
                <div className="w-1/3 bg-cover bg-center"
                     style={{backgroundImage: `url(${props.img})`}}>
                </div>
            : null }
            <div className={`${props.img !== "" ? "w-2/3 px-4 py-2" : ""}`}>
                <CardTitle>{props.title}</CardTitle>
                <div className="-mt-3 mb-2">
                    <Date>{props.date}</Date>
                </div>
                <Text>{props.desc}</Text>
                <div className="mb-2">
                    {typeof props['link'] === 'string' && props.link !== "" ?
                        <CardLink url={props.link}/>
                        : null
                    }
                </div>
                <div className="w-full text-right">
                    <BookButton action={openModal}/>
                </div>
            </div>
        </div>
    );
}


