export const listEloAndSeb = {
    title: "🏡️ Pour notre nid d'amour 👩‍❤️‍💋‍👨️",
    desc : "",
    cover: "https://images.unsplash.com/photo-1451772741724-d20990422508?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1000&q=80",
    coverClasses: "",
    coverHeight: "38vh",
    coverMaxHeight: "350px",
    gifts: [
        {
            giftId: "eloetseb-1",
            title: "Un porte savon pour la cuisine",
            date: "02/12/2021",
            desc: "",
            link: "",
            img: "",
        },
        {
            giftId: "eloetseb-2",
            title: "Un plateau",
            date: "02/12/2021",
            desc: "",
            link: "https://www.laredoute.fr/ppdp/prod-526835522.aspx?dim1=1000&dim2=1000&docid=00000000000001&cod=PSN00094558FR&gclsrc=aw.ds&kard=1&kClkId=211129224933489334&kVsId=211129224933488642&gclid=Cj0KCQiAkZKNBhDiARIsAPsk0WiuytdBZ1fyh4jq3klFo4DI4RfFp52470jnRDmj_kY-I--M1C_CLOoaAqE4EALw_wcB",
            img: "https://cdn.laredoute.com/products/8/1/b/81b5f70f350c14e7bd2dc472458ab9d1.jpg?imgopt=twic&twic=v1/resize=680",
        },
        {
            giftId: "eloetseb-3",
            title: "Un plateau",
            date: "02/12/2021",
            desc: "",
            link: "https://www.maisonsdumonde.com/FR/fr/p/petit-plateau-rectangulaire-en-bois-liberty-M21089880.htm",
            img: "https://medias.maisonsdumonde.com/images/q_auto,f_auto/w_2000/mkp/M21089880_1/petit-plateau-rectangulaire-en-bois-liberty.jpg",
        },
        {
            giftId: "eloetseb-3",
            title: "On va déguster la France",
            date: "02/12/2021",
            desc: "",
            link: "https://www.amazon.fr/va-d%C3%A9guster-France-Fran%C3%A7ois-R%C3%A9gis-Gaudry/dp/2501116720",
            img: "https://images-na.ssl-images-amazon.com/images/I/61HL5NjIODL._SX373_BO1,204,203,200_.jpg",
        },
        {
            giftId: "eloetseb-4",
            title: "Ramens, nouilles et gyozas",
            date: "02/12/2021",
            desc: "de Pippa Middlehurst",
            link: "https://www.amazon.fr/Ramens-nouilles-gyozas-autres-traditionnelles/dp/2017138428/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=2PO8BTWZWFVJE&keywords=ramen+nouilles+et+gyoza&qid=1638479042&s=books&sprefix=ramens+nouill%2Cstripbooks%2C156&sr=1-1",
            img: "https://images-na.ssl-images-amazon.com/images/I/41XbULQZrzL._SX387_BO1,204,203,200_.jpg",
        },
    ]
}