import React from 'react';

export default function Text(props) {

    return (
        <>
            <p className="text-gray-800 font-serif text-base mb-5">
                {props.children}
            </p>
        </>
    );
}

