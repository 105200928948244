import React from 'react';

export default function Hero(props) {

    const heroImage = {
        backgroundImage: "url("+props.cover+")",
        height: props.coverHeight,
        maxHeight: props.coverMaxHeight
    };

    return (
        <>
            <header className="w-full m-0 p-0 bg-cover bg-center" style={heroImage}>
            </header>
        </>
    );
}

