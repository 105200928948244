import React from 'react';

export default function Title1(props) {
    return (
        <>
            <h1 className="font-extrabold sm:text-3xl md:text-4xl text-gray-800 pt-3 pb-10 uppercase">{props.children}</h1>
        </>
    );
}

