import React from 'react';
import {
    Link
} from "react-router-dom";
import Hero from "../Layout/WishList/hero";

export default  function Home() {
    return (
        <div>
            <div className="GistListPage">
                <Hero
                    cover="https://images.unsplash.com/photo-1510658018161-abde712032db?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80"
                    coverHeight="60vh"
                    coverMaxHeight="700px"/>
                <div className="container px-4 md:px-0 max-w-6xl mx-auto -mt-64">
                    <div className="text-center">
                        <h1 className="font-bold text-5xl text-white py-5 px-5 bg-gray-900 bg-opacity-50">
                            🎁 Des idées cadeaux pour les Le Gall Reymond 🎁
                        </h1>
                    </div>
                </div>
                <div className="container px-4 md:px-0 max-w-6xl mx-auto mt-16">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 ">
                        <div>
                            <Link to="/elo">
                                <div className="bg-white shadow-2xl rounded-lg mb-6 tracking-wide">
                                    <div className="px-4 py-8 mt-2 text-center">
                                        <h2 className="font-bold text-2xl text-gray-800 tracking-normal">Les envies d'Elo 🧘‍♀️</h2>
                                    </div>
                                    <div className="md:flex-shrink-0">
                                        <img src="https://images.unsplash.com/photo-1575558418334-6ee474d3e0a3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=2852&q=80" alt="mountains"
                                             className="w-full h-64 rounded-lg rounded-t-none" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/elo-et-seb">
                                <div className="bg-white shadow-2xl rounded-lg mb-6 tracking-wide">
                                    <div className="px-4 py-8 mt-2 text-center">
                                        <h2 className="font-bold text-2xl text-gray-800 tracking-normal">🏡️ Pour notre nid d'amour 👩‍❤️‍💋‍👨️</h2>
                                    </div>
                                    <div className="md:flex-shrink-0">
                                        <img src="https://images.unsplash.com/photo-1451772741724-d20990422508?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80" alt="mountains"
                                             className="w-full h-64 rounded-lg rounded-t-none" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div>
                            <Link to="/seb">
                            <div className="bg-white shadow-2xl rounded-lg mb-6 tracking-wide">
                                <div className="px-4 py-8 mt-2 text-center">
                                    <h2 className="font-bold text-2xl text-gray-800 tracking-normal">Des idées pour Seb 🎁 🙋‍♂️</h2>
                                </div>
                                <div className="md:flex-shrink-0">
                                    <img src="https://images.unsplash.com/photo-1512474932049-78ac69ede12c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" alt="mountains"
                                         className="w-full h-64 rounded-lg rounded-t-none" />
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/*<nav>*/}
            {/*    <ul>*/}
            {/*        <li>*/}
            {/*            <Link to="/elo">La liste d'Elo</Link>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <Link to="/seb">La liste de Seb</Link>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <Link to="/elo-et-seb">La liste d'Elo et Seb</Link>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</nav>*/}
        </div>
    );
}
