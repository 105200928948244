import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './tailwind.output.css';
import GiftListPage from "./Components/GiftListPage";
import Home from "./Components/Home";
import {listElo} from "./Config/list-elo";
import {listSeb} from "./Config/list-seb";
import {listEloAndSeb} from "./Config/list-elo-seb";


function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/elo">
                        <GiftListPage {...listElo} />
                    </Route>
                    <Route path="/seb">
                        <GiftListPage {...listSeb}/>
                    </Route>
                    <Route path="/elo-et-seb">
                        <GiftListPage {...listEloAndSeb} />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
