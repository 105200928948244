import React from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {SubmitButton} from "../../DesignSystem/Button/submit";
import {CancelButton} from "../../DesignSystem/Button/cancel";
import {apiConfig} from "../../../config";

export default function BookForm(props) {

    const bookingValidation = Yup.object().shape({
        email: Yup.string()
            .email("Merci d'entrer une adresse email valide")
            .required('Merci d\'indiquer votre adresse email '),
        name: Yup.string()
            .required('Merci d\'indiquer votre nom complet'),
    });

    const book = async function (values) {
        const response = await  fetch(apiConfig.apiGateway.URL +'/book', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gift_id: values.giftId,
                full_name: values.name,
                email: values.email
            })
        });
        const body = await response.json();

        if(response.status !== 201) {
            throw  body;
        }

    };

    return (
        <div className="mt-2">
            <Formik
                initialValues={{ email: '', name: '', giftId: props.giftId}}
                validationSchema={bookingValidation}
                onSubmit={async (values, {setSubmitting, setFieldError}) => {
                    setSubmitting(true);
                    try {
                        await book(values);
                        setSubmitting(false);
                        props.showThxMessage(true)
                    } catch (e) {
                        setFieldError('email', e.error)
                    }
                }}
            >
                {({handleSubmit, errors, touched, values, handleChange, handleBlur }) => (
                    <form className="mt-6" onSubmit={handleSubmit}>
                        {errors.name && touched.name ? (
                            <div className="text-lg leading-6 font-bold text-red-700">{errors.name}</div>
                        ) : null}
                        {errors.email && touched.email ? (
                            <div className="text-lg leading-6 font-bold text-red-700">{errors.email}</div>
                        ) : null}
                        <div className="rounded-md shadow-sm">
                            <div className="mt-4">
                                <input type="text" required placeholder="Nom complet" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur}
                                       className="px-3 py-3 placeholder-gray-600 text-gray-800 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:shadow-outline w-full pr-10"/>
                            </div>
                            <div className="mt-4">
                                <input type="email" required placeholder="E-mail" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur}
                                       className="px-3 py-3 placeholder-gray-600 text-gray-800 relative bg-white bg-white rounded text-sm border border-gray-400 outline-none focus:outline-none focus:shadow-outline w-full pr-10"/>
                            </div>
                            {/*<input type="hidden" name="giftId" value={props.giftId}/>*/}
                        </div>
                        <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-6">
                            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                <SubmitButton value="Réserver"/>
                            </span>
                            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                                <CancelButton action={() => props.showModal(false)}>Annuler</CancelButton>
                        </span>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );


}