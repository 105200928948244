import React from "react";

export function SubmitButton(props) {
    return (
        <>
            <input type="submit"
                   className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-300 focus:outline-none focus:border-blue-500 focus:shadow-outline-blue-500 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                   style={{ transition: "all .15s ease" }}
                   value={props.value}
            />
        </>
    );
}