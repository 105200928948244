import React from 'react';

export default function Date(props) {

    return (
        <>
            <p className="text-gray-500 text-xs align-top">
                {props.children}
            </p>
        </>
    );
}

