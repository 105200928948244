import React from "react";
import GiftIcon from "../icons/gift";

export default function BookButton(props) {
    return (
        <>
            <button
                className="inline-flex items-center w-24 px-4 py-2 border border-transparent text-lg leading-5 font-medium rounded-md text-gray-800 bg-blue-200 hover:bg-blue-300 focus:outline-none focus:border-blue-500 focus:shadow-outline-blue-500 transition duration-150 ease-in-out shadow-sm"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={() => props.action()}
            >
                <GiftIcon className="h-5 w-5"/>
                Book
            </button>
        </>
    );
}