import React from 'react';
import Title1 from "../DesignSystem/Titles/h1";
import Hero from "../Layout/WishList/hero";
import Container from "../Layout/WishList/container";
import GiftList from "../GiftList";

function GiftListPage(props) {

    return (
        <div className="GistListPage">
            <Hero cover={props.cover} coverHeight={props.coverHeight} coverMaxHeight={props.coverHeight}/>
            <Container>
                <Title1>{props.title}</Title1>
                <GiftList gifts={props.gifts} />
            </Container>
        </div>
    );
}

export default GiftListPage;
