import React from "react";

export default function CardLink(props) {
    return (
        <>
            <a className="inline-flex items-center bg-gray-300 rounded-full px-3 py-1 text-sm font-semibold text-grey-darker mr-2 w-48"
               href={props.url}
               rel="noopener noreferrer"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-full w-full">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                </svg>
                <span className="truncate"> {props.url}</span>
            </a>
        </>
    );
}