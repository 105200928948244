export const listSeb = {
    title: "Des idées pour Seb 🎁 🙋‍♂️",
    desc : "",
    cover: "https://images.unsplash.com/photo-1512474932049-78ac69ede12c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
    coverClasses: "",
    coverHeight: "38vh",
    coverMaxHeight: "350px",
    gifts: [
        {
            giftId: "seb-7",
            title: "Une cafetière V60",
            date: "02/12/2021",
            desc: "",
            link: "https://www.etsy.com/fr/listing/959638070/slow-coffee-o-cafetiere-v60-et-carafe?ref=share_v3_lx",
            img: "https://i.etsystatic.com/28187013/r/il/90fc38/2961821023/il_1588xN.2961821023_6rls.jpg",
        },
        {
            giftId: "seb-8",
            title: "Un sac d'épaule pour appareil photo",
            date: "02/12/2021",
            desc: "",
            link: "",
            img: "https://static.fnac-static.com/multimedia/Images/FR/NR/1d/c2/66/6734365/1541-3/tsp20150325100250/Sac-d-epaule-Lowepro-Adventura-SH-120-II-Noir.jpg"
        },
        {
            giftId: "seb-9",
            title: "Un livre de recette de Wok",
            date: "02/12/2021",
            desc: "",
            link: "https://www.amazon.fr/Wok-Audrey-Goff/dp/201708462X/ref=sr_1_4?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=recette+wok&qid=1638481223&s=books&sr=1-4",
            img: "https://images-na.ssl-images-amazon.com/images/I/51KKyPP5CCL._SX417_BO1,204,203,200_.jpg"
        },
        {
            giftId: "seb-10",
            title: "Turn the ship around !",
            date: "02/12/2021",
            desc: "",
            link: "https://www.amazon.fr/Renversez-vapeur-L-David-Marquet/dp/2892259924/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=22CCXZ8CKUGVS&keywords=turn+the+ship+around+francais&qid=1638481453&sprefix=turn+the+%2Caps%2C166&sr=8-1",
            img: "https://images-na.ssl-images-amazon.com/images/I/51+odC7MQHL._SX336_BO1,204,203,200_.jpg"
        },
        {
            giftId: "seb-11",
            title: "Good to great",
            date: "02/12/2021",
            desc: "",
            link: "https://www.amazon.fr/performance-lexcellence-Devenir-entreprise-leader/dp/274406565X/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=6HSSMMIRZYUE&keywords=good+to+great+francais&qid=1638481618&sprefix=good+to+great+fra%2Caps%2C161&sr=8-1",
            img: "https://images-na.ssl-images-amazon.com/images/I/41tqkn+XH3L._SX331_BO1,204,203,200_.jpg"
        },
        {
            giftId: "seb-12",
            title: "Working Backwards: Insights, Stories, and Secrets from Inside Amazon",
            date: "03/12/2021",
            desc: "",
            link: "https://www.amazon.fr/Working-Backwards-Insights-Stories-Secrets/dp/1529033837",
            img: "https://images-na.ssl-images-amazon.com/images/I/51OTqGCLPuL._SX320_BO1,204,203,200_.jpg"
        },
        {
            giftId: "seb-1",
            title: "101 Words to Sound Smart de Mignon Fogarty",
            date: "29/11/2020",
            desc: "J'en ai entendu du bien ! Il parait que ce livre est très bien pour améliorer son vocabulaire en anglais",
            link: "https://www.amazon.fr/dp/0312573464/?coliid=I1M2IX02JL3W2V&colid=A4P9FKEBGSHS&psc=1&ref_=lv_ov_lig_dp_it",
            img: "https://images-na.ssl-images-amazon.com/images/I/41OK1NCjBsL._SX343_BO1,204,203,200_.jpg",
        },
        {
            giftId: "seb-2",
            title: "Splendor",
            date: "29/11/2020",
            desc: "Il s'agit d'un jeu de société.",
            link: "",
            img: "https://img.lemde.fr/2019/11/25/0/0/0/0/350/0/0/0/8518e29_oO_Ccr-WfECcrJE5OsuvQuLH.jpg",
        },
        {
            giftId: "seb-3",
            title: "Carcassonne",
            date: "29/11/2020",
            desc: "Il s'agit d'un jeu de société.",
            link: "",
            img: "https://img.lemde.fr/2019/11/25/0/0/0/0/350/0/0/0/60bd011_ima9-p1QXEblaaS6-NnXBCjd.jpg",
        },
        {
            giftId: "seb-4",
            title: "Le Cycle de Fondation (Tome 1) - Fondation de Isaac Asimov",
            date: "29/11/2020",
            desc: "Fondation est une série de livre de science fiction très connue. J'aimerai lire le premier tome. Il me semble que c'est celui-ci.",
            link: "",
            img: "https://m.media-amazon.com/images/I/41SFu19Q7vL._SY346_.jpg",
        },
        {
            giftId: "seb-5",
            title: "Des verres à Wisky",
            date: "02/12/2021",
            desc: "",
            link: "https://www.laredoute.fr/ppdp/prod-537464262.aspx?docid=00000000000001",
            img: "https://cdn.laredoute.com/products/b/e/7/be7efd7c06fcec1db73a590828d331b6.jpg?imgopt=twic&twic=v1/resize=800",
        },
        {
            giftId: "seb-6",
            title: "Guide de l'Heraldique",
            date: "02/12/2021",
            desc: "de Claude Wenzler",
            link: "https://www.amazon.fr/Guide-lHeraldique-Wenzler/dp/2737366186/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=Le+guide+de+l%27H%C3%A9raldique&qid=1638479814&s=books&sr=1-1",
            img: "https://images-na.ssl-images-amazon.com/images/I/51pub3qHmVL._SX332_BO1,204,203,200_.jpg",
        },
    ]
}