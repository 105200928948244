import React from "react";
import Card from "../DesignSystem/Card/card";
import Grid from "../Layout/WishList/grid";
import BookModal from "../Book/BookModal";

export default function GiftList(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [selectedGift, setSelectedGift] = React.useState(null);

    const selectGift = () => {
        let filtered = props.gifts.filter(gift => gift.giftId === selectedGift);
        if (typeof filtered[0] === 'undefined') {
            return null
        }
        return filtered[0];
    };

    return (
        <>
            <Grid>
                {props.gifts.map((gift, i) => {
                    return (
                        <div key={i}>
                            <Card {...gift} showModal={setShowModal} selectGift={setSelectedGift} key={i}/>
                        </div>
                    )}
                )}
            </Grid>
            <BookModal showModal={setShowModal} isDisplay={showModal} selectedGift={selectGift()}/>
        </>
    );
}

