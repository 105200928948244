import React from "react";
import GiftIcon from "../../DesignSystem/icons/gift";
import {CancelButton} from "../../DesignSystem/Button/cancel";
import BookForm from "../BookForm";

export default function BookModal(props) {

    const [showThxMessage, setThxMessage] = React.useState(false);

    const resetModal = () => {
        props.showModal(false);
        setThxMessage(false);
    };

    return (
        <>
            {props.isDisplay ? (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => resetModal()}></div>
                        </div>

                        {/*This element is to trick the browser into centering the modal contents.*/}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                             role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 sm:mx-0 sm:h-16 sm:w-16">
                                        <GiftIcon className="h-10 w-10"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h2 className="text-2xl leading-6 font-bold text-gray-900"
                                            id="modal-headline">Réserver une idée cadeau</h2>
                                            <>
                                                <div className="mt-2">
                                                    <p className="text-base leading-5 text-gray-600">Vous êtes sur le point de réserver l'idée : </p>
                                                    <p className="text-base leading-5 text-gray-600 mt-3"><b>{props.selectedGift.title}</b></p>
                                                </div>
                                            </>
                                            {showThxMessage ? (
                                                <>
                                                    <div className="mt-2">
                                                        <h3 className="text-xl leading-6 font-bold text-green-800">
                                                            Merci. Votre réservation est bien prise en compte
                                                        </h3>
                                                    </div>
                                                    <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-6">
                                                        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                                                            <CancelButton action={() => resetModal()}>
                                                                Fermer
                                                            </CancelButton>
                                                        </span>
                                                    </div>

                                                </>) :
                                                <>
                                                    <BookForm showModal={props.showModal} showThxMessage={setThxMessage} giftId={props.selectedGift.giftId}/>
                                                </>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
